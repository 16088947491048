.page-index {
  width: 100%;
  overflow: hidden;
  color: #fff;
  background-color: #369;
}

.page-index h1 {
  color: #fff;
  font-size: 0.4rem;
  text-align: center;
}

.page-index h2 {
  color: #fff;
  font-size: 0.3rem;
  text-align: center;
}

.page-index .slogan {
  color: #fc0;
  font-size: 0.3rem;
  text-align: center;
}

.page-index .question-type {
  display: flex;
  justify-content: space-between;
  margin: 0.4rem 0;
}

.page-index .question-type .item {
  position: relative;
  width: 2.8rem;
  margin: 0 0.2rem;
  padding: 0.4rem;
  background-color: #fff;
  border-radius: 0.05rem;
}

.page-index .question-type .item.select {
  border: solid 0.1rem #f90;
}

.page-index .question-type .item.select::after {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 0.58rem;
  height: 0.58rem;
  background: url("./selected.png") no-repeat 50%/cover #f90;
  border-radius: 50%;
  content: '';
}

.page-index .question-type .item.a .img {
  background: url("./hj.png") no-repeat 50%/cover;
}

.page-index .question-type .item.b .img {
  background: url("./jp.png") no-repeat 50%/cover;
}

.page-index .question-type .item .img {
  width: 2rem;
  height: 1.56rem;
}

.page-index .question-type .item .b {
  color: #000;
  font-weight: bold;
  font-size: 0.38rem;
  text-align: center;
}

.page-index .question-type .item .t {
  color: #f90;
  font-weight: bold;
  text-align: center;
}

.page-index .question-type .item .desc {
  font-weight: bold;
  font-size: 0.26rem;
  text-align: center;
}

.page-index .cell {
  margin-bottom: 0.3rem;
  padding: 0.2rem;
  background-color: #fff;
  border-radius: 0.1rem;
}

.page-index .cell p {
  margin-bottom: 0.1rem;
  font-size: 0.28rem;
}

.page-index .cell p img {
  display: block;
  width: 100%;
}

.page-index .company {
  color: #fff;
  font-size: 0.24rem;
  text-align: center;
}

.page-index .tip-warning {
  color: #b1afaf;
  font-size: 0.2rem;
  text-align: center;
}

.page-index .footer {
  position: relative;
  padding: 0.4rem;
  background-color: #369;
}

.page-index .footer .tip {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  color: #fff;
  font-size: 0.24rem;
  text-align: center;
}

.page-index .footer .btns {
  animation: sharke 1s linear infinite;
}

.page-index .footer .btns .submit {
  font-weight: bold;
  font-size: 0.36rem;
  background-color: #f90;
}

@keyframes sharke {
  0% {
    transform: scale(1);
  }

  15% {
    transform: scale(0.95);
  }

  30% {
    transform: scale(1.05);
  }

  45% {
    transform: scale(1);
  }

  to {
    transform: scale(1);
  }
}