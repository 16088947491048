.page-pay {
  background-color: #defeff;
}

.page-pay .blur .cell .content {
  -webkit-filter: blur(0.1rem);
  filter: blur(0.1rem);
}

.page-pay .banner {
  height: 2.34rem;
  margin: -0.4rem -0.4rem 0 -0.4rem;
  background: url("../../imgs/banner.jpg") no-repeat 50%/cover;
}

.page-pay h6 {
  font-weight: normal;
  font-size: 0.24rem;
}

.page-pay .cell {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0.4rem 0;
  padding: 0.4rem;
  color: #666;
  background: #fff;
  border-radius: 0.1rem;
  box-shadow: rgba(149,157,165,0.2) 0 0.08rem 0.24rem;
}

.page-pay .cell.score .content {
  width: 100%;
}

.page-pay .cell.c1 .content {
  -webkit-filter: blur(0);
  filter: blur(0);
}

.page-pay .cell.c1 .pay-rightnow {
  position: relative;
}

.page-pay .cell.c1 .img img {
  width: 2rem;
}

.page-pay .cell.c1 .type {
  color: #c9c;
  font-weight: bold;
  font-size: 0.48rem;
  text-align: center;
}

.page-pay .cell.c1 .name {
  color: #fc0;
  font-weight: bold;
  font-size: 0.4rem;
  text-align: center;
}

.page-pay .cell .title {
  margin-bottom: 0.2rem;
  color: #333;
  font-weight: bold;
  font-size: 0.4rem;
}

.page-pay .cell .pay-rightnow {
  margin: 0.4rem atuo;
}

.page-pay .cell .content {
  min-height: 2rem;
}

.page-pay .cell .content p {
  margin-bottom: 0.05rem;
  font-size: 0.3rem;
  text-indent: 2em;
}

.page-pay .cell .content p.flex {
  display: flex;
  flex-wrap: wrap;
}

.page-pay .cell .content p.flex span {
  display: block;
  height: 0.6rem;
  margin: 0.1rem;
  padding: 0 0.2rem;
  color: #fff;
  line-height: 0.6rem;
  white-space: wrap;
  text-indent: 0;
  word-break: break-all;
  background-color: #2695d1;
  border-radius: 0.3rem;
}

.page-pay .cell .content p.b {
  font-weight: bold;
  font-size: 0.3rem;
}

.page-pay .cell .content .img {
  margin: 0.2rem 0;
}

.page-pay .cell .content .img img {
  display: block;
  width: 100%;
}

.page-pay .desc {
  margin-top: 0.4rem;
  font-size: 0.34rem;
}

.page-pay .desc p span {
  color: #f90;
}

.page-pay .pay-rightnow {
  position: absolute;
  top: 50%;
  z-index: 99;
  width: 3rem;
  margin: 0.2rem 0;
  font-size: 0.36rem;
  background-color: #f90 !important;
}

.page-pay .pay-rightnow .icon {
  width: 0.56rem;
  height: 0.56rem;
  margin: 0 0.1rem;
  background: url("./imgs/icon-lock.png") no-repeat 50%/cover;
}

.page-pay .chengxin img {
  display: block;
  width: 3rem;
  margin: 0 auto;
}

.page-pay .footer {
  background-color: #f90;
}

.page-pay .footer .btn {
  width: 100%;
  height: 1.1rem;
  color: #fff;
  background: url("./imgs/btn-bg.png") no-repeat 50%/4rem;
  animation: sharke 1s linear infinite;
}

.page-pay .footer .btn:active {
  opacity: 0.75;
}

.page-pay .pop-pay {
  position: relative;
}

.pop-pay .close {
  position: absolute;
  top: 0;
  right: 0;
  width: 0.8rem;
  height: 0.8rem;
  background: url("./imgs/icon-close.png") no-repeat 50%/0.6rem;
}

.pop-pay .pay-button {
  padding: 0 0.4rem;
}

.pop-pay .pay-button .btn {
  margin: 0.2rem 0;
  font-weight: bold;
  font-size: 0.4rem;
}

.pop-pay .pay-button .btn .icon {
  width: 0.6rem;
  height: 0.6rem;
  margin: 0 0.1rem;
}

.pop-pay .pay-button .btn.wechat {
  background-color: #07c160;
}

.pop-pay .pay-button .btn.wechat .icon {
  background: url("./imgs/icon-wechat.png") no-repeat 50%/cover;
}

.pop-pay .pay-button .btn.alipay {
  background-color: #1296db;
}

.pop-pay .pay-button .btn.alipay .icon {
  background: url("./imgs/icon-alipay.png") no-repeat 50%/cover;
}

.pop-pay .tip {
  width: 100%;
  text-align: center;
}

.pop-pay .desc-tip {
  margin: 0.4rem 0.3rem 0.3rem 0.3rem;
  padding: 0.2rem;
  background: #defeff;
  border: solid #2695d1 0.02rem;
  border-radius: 0.1rem;
}

.pop-pay .desc-tip h2 {
  margin-bottom: 0.2rem;
  color: #1296db;
  font-weight: bold;
  font-size: 0.38rem;
  text-align: center;
}

.pop-pay .desc-tip p {
  color: #000;
  font-weight: bold;
  font-size: 0.26rem;
}

.pop-pay .desc-tip .price {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin-top: 0.2rem;
}

.pop-pay .desc-tip .price span {
  padding: 0 0.1rem;
  color: #f90;
  font-size: 0.5rem;
  line-height: 0.5rem;
}

@keyframes sharke {
  0% {
    transform: scale(1);
  }

  15% {
    transform: scale(0.95);
  }

  30% {
    transform: scale(1.05);
  }

  45% {
    transform: scale(1);
  }

  to {
    transform: scale(1);
  }
}