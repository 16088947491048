.com-feedback {
  position: fixed;
  top: 3rem;
  right: 0;
  z-index: 99;
  padding: 0.1rem 0.05rem;
  background-color: rgba(0,0,0,0.4);
  border-radius: 0.1rem 0 0 0.1rem;
}

.com-feedback .icon {
  width: 0.46rem;
  height: 0.46rem;
  background: url("./icon-feedback.png") no-repeat 50%/cover;
}

.com-feedback .title {
  color: #fff;
  font-weight: bold;
  font-size: 0.22rem;
  letter-spacing: -0.01rem;
  text-align: center;
}