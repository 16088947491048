.com-score {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0.2rem 0;
}

.com-score.left .bar-scoller {
  left: 50%;
}

.com-score.right .bar-scoller {
  right: 50%;
}

.com-score .bar {
  position: relative;
  flex: 1;
  height: 0.6rem;
  margin: 0 0.2rem;
  background-color: #edfaff;
  border-radius: 0.3rem;
}

.com-score .bar .divider {
  position: absolute;
  top: -0.03rem;
  left: 50%;
  width: 0.02rem;
  height: 0.66rem;
  margin-left: -0.01rem;
  background-color: #666;
}

.com-score .bar .bar-scoller {
  position: absolute;
  top: 0;
  min-width: 0.2rem;
  height: 0.6rem;
  color: #fff;
  line-height: 0.6rem;
  text-align: center;
  background-color: #4299b4;
}

.com-score .type {
  color: #666;
  font-size: 0.26rem;
}

.com-score .type.win {
  color: #fff;
}

.com-score .type.win .percent {
  color: #4299b4;
}

.com-score .type.win .name {
  color: #4299b4;
}