.page-result {
  background-color: #defeff;
}

.page-result .blur .cell .content {
  -webkit-filter: blur(0.1rem);
  filter: blur(0.1rem);
}

.page-result .banner {
  height: 2.34rem;
  margin: -0.4rem -0.4rem 0 -0.4rem;
  background: url("../../imgs/banner.jpg") no-repeat 50%/cover;
}

.page-result h6 {
  font-weight: normal;
  font-size: 0.24rem;
}

.page-result .cell {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0.4rem 0;
  padding: 0.4rem;
  color: #666;
  background: #fff;
  border-radius: 0.1rem;
  box-shadow: rgba(149,157,165,0.2) 0 0.08rem 0.24rem;
}

.page-result .cell.score .content {
  width: 100%;
}

.page-result .cell.c1 .content {
  -webkit-filter: blur(0);
  filter: blur(0);
}

.page-result .cell.c1 .pay-rightnow {
  position: relative;
}

.page-result .cell.c1 .img img {
  display: block;
  width: 2rem !important;
  margin: 0 auto;
}

.page-result .cell.c1 h1 {
  color: #c9c;
  font-weight: bold;
  font-size: 0.36rem;
  text-align: center;
}

.page-result .cell.c1 h2 {
  color: #fc0;
  font-weight: bold;
  font-size: 0.32rem;
  text-align: center;
}

.page-result .cell .title {
  margin-bottom: 0.2rem;
  color: #333;
  font-weight: bold;
  font-size: 0.4rem;
}

.page-result .cell .content {
  width: 100%;
  min-height: 2rem;
}

.page-result .cell .content p {
  margin-bottom: 0.05rem;
  font-size: 0.3rem;
  text-indent: 2em;
}

.page-result .cell .content p.flex {
  display: flex;
  flex-wrap: wrap;
}

.page-result .cell .content p.flex span {
  display: block;
  height: 0.6rem;
  margin: 0.1rem;
  padding: 0 0.2rem;
  color: #fff;
  line-height: 0.6rem;
  white-space: wrap;
  text-indent: 0;
  word-break: break-all;
  background-color: #2695d1;
  border-radius: 0.3rem;
}

.page-result .cell .content p.b {
  font-weight: bold;
  font-size: 0.3rem;
}

.page-result .cell .content .img {
  margin: 0.2rem 0;
}

.page-result .cell .content .img img {
  display: block;
  width: 100%;
}

.page-result .chengxin img {
  display: block;
  width: 3rem;
  margin: 0 auto;
}

.page-result .bannerx {
  width: 100%;
  margin: 0.4rem 0;
}

.page-result .bannerx img {
  display: block;
  width: 100%;
}

.page-result .bannerx.namematch {
  animation: sharke 2s linear infinite;
}

.page-result .bannerx.iq {
  animation: sharke 2s linear infinite 3.5s;
}

@keyframes sharke {
  0% {
    transform: scale(1);
  }

  7.5% {
    transform: scale(0.95);
  }

  15% {
    transform: scale(1.05);
  }

  22.5% {
    transform: scale(1);
  }

  to {
    transform: scale(1);
  }
}