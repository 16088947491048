.page-qa {
  width: 100%;
  overflow: hidden;
  color: #fff;
  background-color: #369;
}

.page-qa h1 {
  color: #fff;
  font-size: 0.4rem;
  text-align: center;
}

.page-qa h6 {
  color: #fff;
  font-size: 0.3rem;
  text-align: center;
}

.page-qa .question {
  margin-top: 0.3rem;
}

.page-qa .cell {
  margin-bottom: 0.3rem;
  padding: 0.2rem;
  background-color: #fff;
  border-radius: 0.1rem;
}

.page-qa .cell p {
  margin-bottom: 0.1rem;
  font-size: 0.28rem;
}

.page-qa .cell p img {
  display: block;
  width: 100%;
}

.page-qa .body .process {
  position: relative;
  height: 0.16rem;
  margin: 0.4rem 0.2rem;
  background-color: #eeeaea;
  border-radius: 0.08rem;
}

.page-qa .body .process .bar {
  height: 0.16rem;
  background-color: #fc3;
  border-radius: 0.08rem;
}

.page-qa .body .process .total {
  position: absolute;
  top: -0.07rem;
  height: 0.3rem;
  padding: 0 0.08rem;
  color: #fff;
  font-size: 0.22rem;
  line-height: 0.3rem;
  text-align: center;
  background-color: #f90;
  border-radius: 0.15rem;
  transform: translateX(-50%);
}

.page-qa .body .qa {
  margin-top: 0.6rem;
}

.page-qa .body .qa .title {
  margin: 0.2rem 0;
  font-weight: bold;
  text-align: center;
}

.page-qa .body .qa img {
  display: block;
  width: 100%;
}

.page-qa .body .qa .answers {
  padding: 0.2rem 0;
}

.page-qa .body .qa .answers .answer {
  position: relative;
  margin: 0.2rem;
  padding: 0.2rem;
  overflow: hidden;
  line-height: 0.6rem;
  background-color: #eeeaea;
  border-radius: 0.5rem;
}

.page-qa .body .qa .answers .answer:active {
  color: #fff;
  background-color: #f90;
}

.page-qa .body .qa .answers .answer:active .text {
  color: #fff;
}

.page-qa .body .qa .answers .answer.checked .bubble {
  background-color: #f90;
}

.page-qa .body .qa .answers .answer.checked .text {
  color: #fff;
}

.page-qa .body .qa .answers .answer .text {
  position: relative;
  z-index: 9;
}

.page-qa .body .qa .answers .answer .bubble {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  border-radius: 0.5rem;
  pointer-events: none;
}

.page-qa .body .btns {
  display: flex;
  justify-content: start;
  margin-bottom: 0.2rem;
}

.page-qa .body .btns .btn {
  width: 2rem;
  margin: 0 0.1rem;
  background-color: #f90;
}

.x-button.yellow {
  background-color: #f90;
}